@import 'assets/styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
  background-color: #fff;
  @include padding();
}

.kwello-header-logo {
  height: 2.5rem;
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}

.menuIcon {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  margin-left: 0.5rem;
}

.flyout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  .cross {
    color: #fff;
  }
}

.header {
  transition: all 0.3s ease-in-out;
  z-index: 100;
}

.fixedToTop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.menu-flyout-container {
  background-color: var(--blue-900);
  height: 100vh;
}
.logout {
  position: fixed;
  bottom: 2;
  color: var(--gray-300);
  border-top: 1px solid var(--gray-300);
  width: 90%;
  margin: 1rem;
  margin-right: 1rem;
}
