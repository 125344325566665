.filter-list-item {
  padding: 0.15rem 1.25rem;
  &:hover {
    cursor: pointer;
  }
}
.list-name {
  border: solid 1px transparent;
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-list-name {
  border: solid 1px #dedede;
}