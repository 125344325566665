.feed-title-button {
  margin-right: 0.5rem;
  color: #333;
}
.alert-icon {
  margin-top: 0 !important;
}
.export-spinner {
  min-height: 28px !important;
  padding: 0;

  > div {
    width: 28px;
    height: 28px;
  }
}

.export-icon {
  cursor: pointer;
}

.feed-body-container {
  margin-top: -0.6rem; //to remove the padding added by the card header
  background-color: 'red';
  &:before {
    mask: url(./../../../../assets/images/feedBackground.svg) repeat;
    background-color: var(--primary-color);
    opacity: 0.25;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

.feed-body-content {
  height: 101%;
  right: 0;
  left: 0;
  padding: 0px 1.25rem 0 1.25rem;
}
