@import "./variables";

@mixin mobile {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin largeTablet {
  @media (min-width: #{$large-tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin largeDesktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

$mobile-spacing: 0.5rem;
$tablet-spacing: 0.75rem;
$desktop-spacing: 1rem;
$large-desktop-spacing: 1rem;

@mixin padding($side: false, $additional: "") {
  @if $side {
    padding-#{$side}: $mobile-spacing #{$additional};
  } @else {
    padding: $mobile-spacing #{$additional};
  }

  @include tablet {
    @if $side {
      padding-#{$side}: $tablet-spacing #{$additional};
    } @else {
      padding: $tablet-spacing #{$additional};
    }
  }

  @include desktop {
    @if $side {
      padding-#{$side}: $desktop-spacing #{$additional};
    } @else {
      padding: $desktop-spacing #{$additional};
    }
  }

  @include largeDesktop {
    @if $side {
      padding-#{$side}: $large-desktop-spacing #{$additional};
    } @else {
      padding: $large-desktop-spacing #{$additional};
    }
  }
}

@mixin margin($side: false, $additional: "") {
  @if $side {
    margin-#{$side}: $mobile-spacing #{$additional};
  } @else {
    margin: $mobile-spacing #{$additional};
  }

  @include tablet {
    @if $side {
      margin-#{$side}: $tablet-spacing #{$additional};
    } @else {
      margin: $tablet-spacing #{$additional};
    }
  }

  @include desktop {
    @if $side {
      margin-#{$side}: $desktop-spacing #{$additional};
    } @else {
      margin: $desktop-spacing #{$additional};
    }
  }

  @include largeDesktop {
    @if $side {
      margin-#{$side}: $large-desktop-spacing #{$additional};
    } @else {
      margin: $large-desktop-spacing #{$additional};
    }
  }
}

@mixin negative-margin($side: false, $additional: "") {
  @if $side {
    margin-#{$side}: -$mobile-spacing #{$additional};
  } @else {
    margin: -$mobile-spacing #{$additional};
  }

  @include tablet {
    @if $side {
      margin-#{$side}: -$tablet-spacing #{$additional};
    } @else {
      margin: -$tablet-spacing #{$additional};
    }
  }

  @include desktop {
    @if $side {
      margin-#{$side}: -$desktop-spacing #{$additional};
    } @else {
      margin: -$desktop-spacing #{$additional};
    }
  }

  @include largeDesktop {
    @if $side {
      margin-#{$side}: -$large-desktop-spacing #{$additional};
    } @else {
      margin: -$large-desktop-spacing #{$additional};
    }
  }
}

@mixin scrollbar($element: "&", $size: 15px) {
  padding-right: 15px;

  #{$element}::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  #{$element}::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
  }

  #{$element}::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
    border-radius: 5px;
    z-index: 3;
  }

  #{$element}::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover);
  }
}
