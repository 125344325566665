$spacing: 0.75em;

.container {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-xs);
  padding: $spacing;
  display: flex;
  height: 100%;

  &.selected {
    border-color: var(--primary-color);
  }

  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
}

.profile-picture-container {
  button {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }
}

.details-container {
  flex-grow: 1;
  margin-left: $spacing;
  display: flex;
  flex-direction: column;
}

.heading-container {
  display: flex;
  justify-content: space-between;
}

.menu {
  display: flex;
  align-items: center;

}

.name {
  padding: 0.15em 0;

  button {
    padding: 0;
    font-weight: bold;
    font-size: 1.45em;
    white-space: normal;
    line-height: 1.2em;
    text-align: left;

    &:hover {
      background: transparent;
    }
  }
}

.badges {
  padding-top: 0.4em;
}

.body-container {
  flex-grow: 1;
}

.body-block {
  margin-bottom: $spacing;

  &:last-child {
    margin-bottom: 0;
  }
}
