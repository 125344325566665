.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  h3 {
    font-weight: bold;
  }
  position: fixed;
  width: 101vw;
  top: 0;
  z-index: 5;
}

.mobile-footer {
  @extend .mobile-header;
  justify-content: center;
  font-weight: bold;
  position: fixed;
  top: auto;
  bottom: 0;
}

.mobile-header-goback {
  @extend .mobile-header;
  justify-content: left;
  display: flex;
  align-items: center;
  width: 103vw;
  svg {
    margin-left: 0.5rem;
    margin-right: 1rem;
    padding-top: 5px;
  }

  padding: 0.5rem;
}

.title {
  margin-top: 0.5rem;
  font-size: 21px;
}
