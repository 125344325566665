.badge.badge-light,
.badge.bg-light {
  background-color: #f7f7f7;
  color: var(--text-main-color);
}

.badge.bg-success {
  background-color: rgba(10, 207, 131, 0.05) !important;
  color: #0acf83 !important;
}

.badge.bg-danger {
  background-color: rgba(207, 10, 10, 0.05) !important;
  color: #cf0a0a !important;
}

.badge.bg-info {
  background-color: #cbecff !important;
}

.badge.bg-warning {
  background-color: #ffe4cb !important;
}

.badge.bg-success-light {
  background-color: #d2f4d3 !important;
}
