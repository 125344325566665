#flyoutMenu {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.4s cubic-bezier(0, 0.52, 0, 1);
  overflow: scroll;
  z-index: 1000;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
}

#flyoutMenu.hide {
  transform: translate3d(-90vw, 0, 0);
}

#flyoutMenu.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

.cross {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}
.menuIcon {
  margin-right: 0.75rem;
}
