@import "assets/styles/mixins";

.search-bar {
  &.scrolled {
    .left-side {
      @media (max-width: $desktop-width) {
        width: 100%;
        justify-content: space-between;
        margin-right: 20px;
      }
    }
    background-color: var(--card-background-color);
    border-bottom: 0;
    z-index: 97;

    & > span:first-child > .dropdown:first-child {
      .btn-dropdown {
        border: none;
        color: var(--primary-color);
        padding: 0;
        font-weight: 700;
        label {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
        .hamburger-menu-icon {
          margin-top: 0.25rem;
          span {
            background: var(--primary-color) !important;
          }
        }
      }
    }
    & > .h4 {
      margin-left: 0.5rem !important;
    }
  }
  .search-bar-container {
    background-color: var(--darker-background-color);
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius-sm);
    overflow-x: hidden;
    display: none;

    @include desktop {
      display: flex;
    }

    .filters-container {
      flex-wrap: wrap;
      margin-top: -0.5rem;
      & > * {
        margin-top: 0.5rem;
      }
    }
    .search-badges-container {
      overflow-x: hidden;
      & > div {
        position: initial !important;
      }
    }
    .filter-dropdown > .btn,
    .saved-search-badge {
      border-radius: var(--border-radius-xs);
      border: 1px solid var(--primary-color);

      div:first-child {
        border-right: 1px solid var(--primary-color);
      }

      button {
        border: 0;
      }

      svg {
        stroke: var(--primary-color) !important;
      }
    }

    .save-filters-button {
      border-left: 1px solid var(--border-color);
      border-radius: 0;
      white-space: nowrap;
    }
  }

  .filter-badge-container {
    white-space: nowrap;
    .btn-dropdown:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &::after {
        display: none;
      }
    }
    .button-container {
      border: 1px solid var(--border-color);
      border-left: none;
      position: relative;
      width: 2rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.active {
      color: var(--primary-color);
      border-color: var(--hover-border-color);
      background-color: var(--hover-color);
      .btn-dropdown {
        color: var(--primary-color);
        border-color: var(--hover-border-color);
        background-color: var(--hover-color);
      }
      .button-container {
        border-left: none;
      }
    }
  }

  .filter-dropdown.m-tablet {
    @include desktop {
      display: none;
    }

    & > .btn {
      height: 56px;
      width: 56px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .tablet-badges-container {
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 400px;
      background-color: var(--darker-background-color);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-sm);
      .header {
        height: 54px;
      }
    }

    & > .dropdown-menu {
      left: -400px !important;
    }
  }
}
