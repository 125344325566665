.date-range-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .25rem;
  border: 1px solid transparent;

  &.selected {
    background-color: var(--hover-color);
    color: var(--primary-color);
  }

  &:hover {
    cursor: pointer;
    border-color: var(--primary-color);
  }
}