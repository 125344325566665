@import "assets/styles/mixins";

.detail-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: .75rem 0;

  &:first-child {
    padding-top: .25rem;
  }

  span {
    display: block;

    &.detail-label {
      padding-right: .5rem;
      width: 40%;

      @include desktop {
        width: 30%;
      }
    }

    &.detail-value {
      width: 60%;

      @include desktop {
        width: 70%;
      }
    }
  }
}