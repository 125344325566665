.wrapper {
  border: 1px solid var(--border-color);
  border-radius: 100%;
  overflow: hidden;
  position: relative;

  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
}

.background {
  position: absolute;
  background-position: center;
  background-size: 100% 150%;
  background-repeat: no-repeat;

  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.image {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
