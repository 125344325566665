.feed-title-button {
  margin-right: 0.5rem;
  color: #333;
}

.export-spinner {
  min-height: 28px !important;
  padding: 0;

  > div {
    width: 28px;
    height: 28px;
  }
}

.export-icon {
  cursor: pointer;
}

.feed-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
