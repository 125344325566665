@import 'assets/styles/mixins';

.container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.scroll-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  @include scrollbar();
}

.no-scroll {
  height: 100%;
}

.scroll-to-top {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 20px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border: 1px solid var(--border-color);

  &.show {
    opacity: 1;
  }
}

.scroll-to-bottom {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 20px;
  border-radius: 50%;
  padding: 0;
  border: 0;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border: 1px solid var(--border-color);

  &.showDown {
    opacity: 1;
  }
}
