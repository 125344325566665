@import 'assets/styles/mixins';

.modal-container {
  font-size: 0.875rem;
  min-width: 300px;
  max-width: 600px;

  @include desktop {
    max-width: 600px;
  }

  @include largeDesktop {
    max-width: 800px;
  }
}

.width-small,
.width-medium,
.width-large {
  width: 80vw;
}

.width-mobile {
  max-width: 400px;
  width: 85vw;
}

.width-small {
  max-width: 400px;
}

.width-medium {
  max-width: 800px;
}

.width-large {
  max-width: 1200px;
}

.close-button {
  background: transparent;
}

.modal-title {
  line-height: 28px;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
  margin-top: 0.5rem;
}
.no-underline-footer {
  @extend .modal-footer;
  border-top: none;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -20px;
  margin-right: -20px;
}
.no-underline-title-container {
  @extend .title-container;
  border-bottom: none;
}

.close-icon {
  color: #473a2e;
  &:hover {
    cursor: pointer;
  }
}
