.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5rem;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.actions {
  display: flex;
  align-items: center;
}