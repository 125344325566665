@import "assets/styles/variables";

@function size-class($size) {
  @if $size == "" {
    @return "";
  }

  @return "-#{$size}";
}

@for $s from 1 through length($screens) {
  $screen: nth($screens, $s);
  $size: nth($screen-sizes, $s);

  @media only screen and (min-width: #{$size}) {
    @for $i from 1 through 12 {
      .col-span#{size-class($screen)}-#{$i} {
        grid-column: span #{$i};
      }
      .row-span#{size-class($screen)}-#{$i} {
        grid-row: span #{$i};
      }
      @for $x from 1 through 12 {
        @if $x >= $i {
          .col#{size-class($screen)}-#{$i}-#{$x} {
            grid-column-start: #{$i};
            grid-column-end: #{$x + 1};
          }
          .row#{size-class($screen)}-#{$i}-#{$x} {
            grid-row-start: #{$i};
            grid-row-end: #{$x + 1};
          }
          .col#{size-class($screen)}-#{$i} {
            grid-column-start: span #{$i};

            & > *:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
