@import 'assets/styles/mixins';
@import '../../ListsPage.module.scss';

.edit {
  @extend .scroll-wrapper;
  flex: 0;
  @include margin('bottom');
}

.edit-actions {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.delete-actions {
  button {
    margin-right: 0.5rem;
  }
}
