.date-picker {
  display: flex;
  input {
    border: 1px solid var(--border-color) !important;
    border-radius: var(--border-radius-sm);
    padding: 0.4rem;
    &:focus {
      outline: none;
    }
  }
}
