@import 'assets/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.float {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.item-group {
  position: relative;
  display: flex;
}
