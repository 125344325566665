.login {
  padding-top: 100px;
  width: 480px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: calc(100vh - 240px - 44px);

  .card {
    margin-top: 70px;
  }

  a {
    font-size: 0.75rem;
  }

  margin-left: auto;
  margin-right: auto;
}

.banner {
  height: 240px;
  background-position: center;
  background-size: cover;
}

.mobileBanner {
  height: 99px;
  background-position: center;
  background-size: cover;
}

.video-section {
  padding-top: 192px;
  padding-bottom: 192px;
  max-width: 1240px;
  font-weight: 500;

  iframe {
    max-width: 600px;
    max-height: 340px;
    width: 100%;
    height: 100%;
  }

  .btn {
    margin-top: 2.75rem;
  }

  ul {
    list-style: none;
    padding-left: 1rem;

    li::before {
      content: '\2022';
      color: #f87700;
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
    }
  }
}

.footer {
  background-color: rgba(51, 51, 51, 0.05);
  font-size: 12px;
  height: 44px;
  margin: auto;
  justify-content: center;

  & > * {
    margin-right: 1.5rem;
  }
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  background-color: #f0f0f0;
  width: 100% !important;
  margin: autocalc;
  justify-content: center;
}

.header {
  background-color: #ffffff;
  font-size: 12px;
  height: 44px;

  & > * {
    margin-right: 1.5rem;
  }
}

.icon-text {
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 8px;
  }
}

h1 {
  color: #f87700;
}

h5 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: black;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-logo {
  padding-right: 2rem !important;
}

.btn-primary {
  background-color: #f87700;
  border: none;
  outline: none;
}

.compliance-wrapper {
  width: 1072px;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;

  .compliance-header {
    padding: 38px 2px;
  }

  .compliance-content {
    width: 100%;
    background-color: white;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

.row-styles {
  min-height: 100vh;
}

.col-styles {
  padding: 0px;
}

.kwello-footer-icon,
.kwello-header-icon {
  height: 50px;
  width: 100px;
}
.kwello-icon-section {
  margin-bottom: 25px;
}
.kwello-login-icon {
  height: 50px;
  width: 120px;
}
