@import 'assets/styles/mixins';
@import 'Pages/Lists/Components/ListItem/ListItem.module.scss';

.disabled {
  opacity: 0.65;
  pointer-events: none;
}

.button-icon {
  padding: 6px 10px;
}

.click-icon {
  padding: 6px 10px;
  &:hover {
    background-color: #ededed;
    cursor: pointer;
  }
}
