.filter-list-item {
  padding: 0.25rem 1.25rem;
  &:hover {
    background-color: #e9ecef;
    cursor: pointer;
  }
}

.saved-search-list-item {
  @extend .filter-list-item;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
