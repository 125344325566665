.toast {
  min-height: 48px;
  background: white;
  border-radius: 4px;
  padding: 2px 30px 2px 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
}

.success {
  border: 1px solid var(--color-success);
  color: var(--color-success);
  background: var(--color-success-background);
}

.information {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: rgba(248, 119, 0, 0.05);
}

.error {
  border: 1px solid var(--color-error);
  color: var(--color-error);
  background: var(--color-error-background);
}
