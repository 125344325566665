.field-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
  }

  &.required {
    .label {
      &::after {
        content: '*';
        color: var(--color-error);
        margin-left: 0.5rem;
      }
    }
  }
}

.label {
  font-size: 1rem;
  display: block;
  padding-bottom: 0.25rem;
}

.input {
  border: 1px solid var(--border-color);
  width: 100%;
  padding: 0.5rem;
  border-radius: var(--border-radius-sm);

  &:active,
  &:focus {
    outline: 0;
  }
}

.input-message-container {
  display: flex;
}

.error-message-container {
  flex: 1;
  margin-right: 0.5rem;
}

.counter-message-container {
  font-size: 0.85em;
  color: var(--bs-gray-600);
  margin-top: 0.25rem;
}

span.error {
  display: block;
  color: var(--color-error);
  margin-top: 0.25rem;
  font-size: 0.85em;
}

.thumbnail {
  img {
    max-height: 3rem;
    max-width: 12rem;
  }
}

.file-selection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.max-size {
  display: flex;
  justify-content: flex-end;
}

.delete-image {
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: space-between;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  padding: 0.5rem;
  .trash {
    border-left: 1px solid var(--border-color);
  }
}

.disabled-text {
  opacity: 0.6;
}

.insights {
  background-color: #f3f8ff;
}
