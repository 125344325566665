@import 'assets/styles/mixins';
.mobile-card {
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  .title-underline {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 0.5rem;
    line-height: 21px;
    font-size: 18px;
  }
}

.title {
  line-height: 23px;
  font-size: 20px;
  margin-bottom: 0.5rem;
}
.sub-text {
  opacity: 0.6;
}
