@import '~bootstrap/scss/bootstrap';
@import './assets/styles/bootstrapOverrides/index';
@import './assets/styles/text';
@import './assets/styles/utilities';
@import './assets/styles/mixins';
@import './assets/styles/forms';
@import './assets/styles/media';
@import './assets/styles/tooltip';
@import './assets/styles/variables';

:root {
  --mobile-width: #{$mobile-width};
  --tablet-width: #{$tablet-width};
  --desktop-width: #{$desktop-width};
  --large-desktop-width: #{$large-desktop-width};

  --color-success: #0acf83;
  --color-success-background: linear-gradient(
      0deg,
      rgba(10, 207, 131, 0.05),
      rgba(10, 207, 131, 0.05)
    ),
    #ffffff;
  --color-error: #d93038;
  --color-error-background: rgba(217, 48, 57, 0.05);

  --mobile-spacing: 0.5rem;
  --tablet-spacing: 0.75rem;
  --desktop-spacing: 1rem;
  --large-desktop-spacing: 1rem;

  --spacing: var(--mobile-spacing);

  --dark: #141e28;
  --gray-100: #e3e6ee;
  --gray-300: #bdc6dc;
  --gray-500: #8a9eb6;
  --gray-700: #4a5e73;
  --gray-900: #273a4b;

  --blue-100: #e8f2fd;
  --blue-300: #a4bcd5;
  --blue-500: #03386f;
  --blue-700: #092e54;
  --blue-900: #182736;

  --orange-500: #f76900;

  @media (min-width: var(--tablet-width)) {
    --spacing: var(--tablet-spacing);
  }

  @media (min-width: var(--desktop-width)) {
    --spacing: var(--desktop-spacing);
  }

  @media (min-width: var(--large-desktop-width)) {
    --spacing: var(--large-desktop-spacing);
  }
}

@font-face {
  font-family: 'primary-font';
  src: url(./assets/fonts/roboto/Roboto-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-size: 14px;
  font-family: primary-font;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: var(--primary-text-color);
  background-color: var(--page-background-color);
  font-weight: 500;
}

.App {
  @media (max-width: $desktop-width) {
    margin-top: 60px;
  }
}

button {
  color: var(--primary-text-color);
}

@media (max-width: $desktop-width) {
  @include scrollbar();
  @include scrollbar('body', 20px);
}

// Global overrides for dropdowns

.margin-bottom {
  margin-bottom: 20px;
}

// Global React modal backdrop colour override
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 5001 !important;
}

// Global React joyride bootstrapOverrides

.react-joyride__tooltip {
  border: 2px solid var(--primary-color);
  border-radius: 0 !important;

  button[data-action='close'] {
    display: none;
  }

  div:nth-child(1) div {
    padding: 0 !important;
    margin: 0 !important;
  }

  div:nth-child(2) {
    margin: 0 !important;

    div button {
      background-color: var(--filter-background-color) !important;
      border: 1px solid var(--border-color) !important;
      display: flex;
    }
  }
}

.__floater__arrow {
  display: none;
}

.btn-primary {
  background-color: var(--primary-color);
  color: #fff !important;
  border: 1px solid #dedede;
  border-radius: var(--border-radius-xs);
  font-family: primary-font;
  padding: 0 0.4rem;
}

.warning-btn {
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: var(--border-radius-xs);
  font-size: 0.875rem;
  padding: 0 0.4rem;
  color: #cf0a0a;
  max-height: 30px !important;
}

.btn-bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.no-border {
  border: 0 !important;
}

.transparent,
.transparent:hover {
  background: none !important;
  border: none !important;
}

.Toastify__toast {
  box-shadow: none !important;
  padding: 0;
  margin: 0 100px 10px 0;
  min-height: 48px;
  line-height: 100%;
}

.Toastify__toast-body {
  min-height: 48px;
  padding: 0;
  margin: 0;
}

.Toastify__toast-container {
  width: 412px;
}

.Toastify__close-button {
  right: 10px;
  position: absolute;
  top: 14px;
}

.reset {
  background-color: var(--filter-background-color) !important;
  border-color: var(--border-color) !important;
  color: #000 !important;
}

.profile-picture {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  cursor: pointer;
}

.default-padding {
  padding: 20px;
}

.hide-mobile {
  @media (max-width: $desktop-width) {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@for $i from 1 through 12 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}

.align-left {
  display: flex;
  float: right;
  justify-content: flex-start;
}

.align-right {
  display: flex;
  float: right;
  justify-content: flex-end;
}

.align-center {
  display: flex;
  justify-content: center;
}

/* LOGIN STYLES */

.login {
  padding-top: 100px;
  width: 480px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: calc(100vh - 240px - 44px);

  .card {
    margin-top: 70px;
  }

  a {
    font-size: 0.75rem;
  }

  margin-left: auto;
  margin-right: auto;
}

.banner {
  height: 240px;
  background-position: center;
  background-size: cover;
}

.video-section {
  padding-top: 192px;
  padding-bottom: 192px;
  max-width: 1240px;
  font-weight: 500;

  iframe {
    max-width: 600px;
    max-height: 340px;
    width: 100%;
    height: 100%;
  }

  .btn {
    margin-top: 2.75rem;
  }

  ul {
    list-style: none;
    padding-left: 1rem;

    li::before {
      content: '\2022';
      color: #f87700;
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
    }
  }
}

.footer {
  background-color: rgba(51, 51, 51, 0.05);
  font-size: 12px;
  height: 44px;
  margin: auto;
  justify-content: center;

  img {
    height: 24px;
  }

  & > * {
    margin-right: 1.5rem;
  }
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  background-color: #f0f0f0;
  width: 100% !important;
  margin: autocalc;
  justify-content: center;
}

.header {
  background-color: #ffffff;
  font-size: 12px;
  height: 44px;

  & > * {
    margin-right: 1.5rem;
  }
}

.icon-text {
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding-left: 8px;
  }
}

h1 {
  color: #f87700;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-logo {
  padding-right: 2rem !important;
}

.pb-3,
.p-3 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.mx-3 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.btn-primary {
  background-color: #f87700;
  border: none;
  outline: none;
}

.overflow-hidden {
  overflow: hidden;
}

.bold {
  font-weight: bold;
}

.h100 {
  height: 100% !important;
}
