// Super specific class tree to access existing dropdown menu when only inside a Kwello Card
// Or we'd have to re-build the drop down menus
.kwello-card {
  .kwello-card-header-title {
    .card-drop-down-title {
      display: flex;
      white-space: pre-wrap; /* css-3 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */

      font-size: var(--card-header-font-size);
      text-align: left;
      font-weight: 1000;
      letter-spacing: 0.5px;

      svg {
        width: 20px;
        margin-left: 0.5rem;
      }
    }
  }
}
