.nav-tabs {
  .nav-link {
    color: var(--text-main-color);
    font-weight: 700;
    font-size: 1.125rem;
    &.active {
      color: var(--primary-color);
      border: none;
      border-bottom: 1px solid var(--primary-color);
    }
  }
}

.tab-content {
  padding-top: 1rem;
}
