.help-center {
  color: var(--gray-500);
  font-size: 14px;
  line-height: 20px;
  display: flex;
  gap: 1rem;
  align-items: center;
  &:hover {
    color: white;
  }
  margin-top: 1rem;
}
