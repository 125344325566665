.input {
  position: relative;

  svg {
    z-index: -1;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.date-picker {
  background-color: transparent;
}
