@import 'assets/styles/mixins';

.card {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fff;
  padding: 1rem;

  &.feed-card {
    padding: 0;
  }
}

.sticky {
  position: fixed;
  width: inherit;
  bottom: 1.5rem;

  &.full-screen {
    height: 100% !important; // Important here to override full screen height when Sticky
  }
}

.full-screen {
  position: unset;
}

.card-as-body {
  padding: 0;
  border: 0;
}

.card-header {
  display: flex;
  flex: 0;
  flex-direction: row;
  padding-bottom: 0.6rem;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 0.5rem;

  &.feed-card {
    margin: 0;
    padding: 1rem 1rem 0.8rem 1rem;
  }

  & > * {
    display: flex;
    align-items: center;
  }

  & > *:first-child {
    padding-left: 0;
  }

  & > *:last-child {
    padding-right: 0;
  }
}

.card-header-title {
  flex: 1;
  font-size: var(--card-header-font-size);
  font-weight: 1000;
  letter-spacing: 0.5px;
}

.card-header-max-button {
  flex: 0;
}

.card-header-menu-button {
  flex: 0;
}

.card-body {
  flex: 1;
  width: 100%;
  position: relative;
  overflow: auto;
}

.card-content-container {
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.show-scrollbar,
  &.show-scrollbar-feed {
    @include scrollbar();
  }
  &.show-scrollbar-feed {
    padding-right: 0px;
  }
}

.card-footer {
  border-top: 1px solid var(--border-color);
  padding-top: 0.5rem;
}
