.card {
  border-color: var(--border-color);
  border-radius: 5px;
  .card-title {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    font-size: var(--card-header-font-size);
    padding-bottom: 0.75rem;
  }

  .card-footer {
    border-top: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    font-size: var(--card-header-font-size);
    padding-top: 0.75rem;
    background: var(--card-background-color);
    padding-right: 0;
    padding-left: 0;
  }

  .card-body {
    padding: 0;
  }
  .accordion-header {
    .accordion-button::after {
      margin-bottom: 0.5rem;
    }
    .card-title {
      flex-grow: 1;
      padding-right: 0.5rem;
    }
  }
}

.hide-accordion-title {
  .card-title {
    border-bottom: none;
    margin: 0;
  }
}
