.dropdown-header > div {
  background-color: var(--filter-background-color);
  padding: 4px;
  color: var(--text-main-color);
  font-weight: 700;
}
.dropdown-item {
  padding-left: 1.25rem;
}
.dropdown-menu.show {
  min-width: auto;
  font-size: 0.875rem;
  .form-control.filter-search {
    background-color: var(--filter-background-color);
    border-left: none;
    padding-left: 0;
    &::placeholder {
      font-style: italic;
    }
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }

  .input-group-text {
    background-color: var(--filter-background-color);
    border-right: none;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}

.dropdown {
  position: initial !important;
  &.inset {
    .dropdown-submenu + .dropdown-menu {
      left: 286px !important;
      top: -1px !important;
    }
  }
  &.dropdown-submenus-left {
    .dropdown-submenu + .dropdown-menu {
      left: 0 !important;
      transform: translateX(-100%);
    }
  }
}

.dropdown-item.active {
  border-color: var(--hover-border-color);
  background-color: var(--hover-color);
  color: var(--primary-color);
}

.dropdown-submenu-container {
  position: initial;
  a.dropdown-submenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      position: relative;
      top: 0;
      margin-left: 15px;
    }
    &::before {
      display: none;
    }
  }
}

.open-top + .dropdown-menu.show {
  transform: translateY(calc(-46% + 30px));
}

.dropdown-header {
  padding: 0.5rem;
}

.dropstart .dropdown-toggle::before {
  display: none;
}
