.container {
  margin-bottom: 1rem;
  transition: all 0.5s ease-in-out;
  border-radius: 2px;
}

.rotate {
  transform: rotate(180deg);
}

.icon-name {
  margin-top: 1rem;
  text-align: center;

  .icon-inactive,
  .text-inactive {
    color: var(--blue-300);
  }

  &:hover {
    cursor: pointer;
    color: #fff;
  }
}

.icon-not-expanded {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding: 0.5rem;
  font-size: 12px;
  line-height: 1.2em;
}

.active {
  background-color: var(--dark);
  color: #fff;
  border-radius: 4px;

  svg {
    color: #f36633;
  }
}
