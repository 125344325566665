.content-image {
  display: inline-block;
  width: 100%;
}

.image-wrapper {
  position: relative;
}

.collapsed {
  height: 150px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.expand-collapse-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: none;
  color: #fff;
  background: #000;
  opacity: 0.7;
  padding: 5px;
  border-radius: 2px;

  &:hover {
    opacity: 0.9;
  }
}

.badge-source {
  svg {
    margin-right: 0.25rem;
  }
}
