@import 'assets/styles/mixins';

.no-filters {
  @include padding();
  text-align: center;
  font-size: 1.25rem;
}

.button-key {
  margin-right: 0.25rem;
}

.button-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}

.button-selected {
  border-color: var(--primary-color);
}
