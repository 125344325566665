.container {
  border-radius: var(--border-radius-sm);
  border: 1px solid;
  padding: 0.5rem;
  font-family: primary-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
}

.information {
  background: linear-gradient(0deg, var(--hover-color), var(--hover-color)), #ffffff;
  border-color: var(--primary-color);
  color: var(--primary-color);
  & > svg > path {
    stroke: var(--primary-color);
  }
}
