.accordion-header {
  font-size: inherit;
  background: inherit;
  color: inherit;
  border-bottom: none;
}
.accordion-button,
.accordion-button:not(.collapsed) {
  font-size: inherit;
  background: inherit;
  color: inherit;
  border-bottom: none;
  padding: 0;
  box-shadow: none;
}

.accordion-button:focus {
  border: none;
  box-shadow: none;
}

.accordion-item {
  background: inherit;
  color: inherit;
}

.accordion-button.collapsed::after {
  background: var(--text-main-color);
  width: 14px;
  height: 14px;
  mask: url(../../icons/arrow.svg);
  mask-size: 14px 14px;
  transform: rotate(90deg);
}
.accordion-header.dark > .accordion-button::after {
  background: var(--text-main-color);
  margin: 0 10px 18px 10px;
}
.accordion-button:not(.collapsed)::after {
  background: var(--text-main-color);
  width: 14px;
  height: 14px;
  mask: url(../../icons/arrow.svg);
  mask-size: 14px 14px;
  transform: rotate(-90deg);
}

.accordion-header.menu-collapsed .accordion-button::after {
  display: none;
}
