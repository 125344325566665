.letter-avatar {
  background-color: var(--gray-500);
  width: 2rem;
  height: 2rem;
  color: var(--blue-900);
}

.title {
  color: var(--gray-500);
  font-size: 16px;
  line-height: 24px;

  &:hover {
    cursor: pointer;
    color: white;
  }
}

.expanded-container {
  background-color: var(--blue-900);
  border-top: 1px solid var(--gray-500);
  padding: 1rem 1rem 1rem 1rem;
  z-index: 10;
  transition: 0.5s ease-in-out;
}

.demo-mode-toggle {
  background-color: #fff8f2;
  border-radius: 4px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  color: #f36633;
  font-size: 14px;
  line-height: 20px;
  margin-top: 1rem;
  &:hover {
    color: #f36633;
  }
}
