.search-container {
  min-width: 390px;
  overflow-x: hidden;
 
  .button-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

