// All styles are important to override BootStrap
.menu-item {
  margin: 0 !important;
  padding: 0 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.menu-item-link {
  display: flex;
  align-items: center;
  flex-direction: row;

  &:not(:global(.icon)) {
    svg {
      margin-right: 0.5em;
    }
  }

  &:global(.active) {
    color: var(--primary-color);
  }

  &:global(.nav-bar) {
    margin-right: 1em;
  }

  &:global(.dropdown) {
    margin: 0.75em 1.25em;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.kolSearch {
  margin-right: 0.4rem;
}

.beta-badge {
  margin-left: 0.25rem;
  border-radius: 0.25rem;
  background-color: var(--primary-color);
  color: var(--text-main-color);
}
