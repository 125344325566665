.notification-icon {
  padding: 6px 10px;
  &:hover {
    background-color: #ededed;
    cursor: pointer;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
