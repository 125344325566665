.mobile-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.applied-filters {
  color: var(--primary-color);
}

.filter-count-badge {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  border-radius: 0.5rem;
  font-size: small;
  margin-left: -1rem;
  vertical-align: super;
}

.filter-count-background {
  background-color: var(--primary-color);
  color: #fff;
}

.search-icon {
  margin-right: 0.5rem;
}
