@import 'assets/styles/mixins';

.container {
  @include padding('left');
  @include padding('right');
  @include padding('bottom');
  overflow-y: auto;

  &::-webkit-scrollbar {
    background-color: #fff;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    display: none;
  }

  &.side-bar {
    margin-left: 78px;
  }

  &.full-screen {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.mobile-container {
  @extend .container;
  overflow-x: hidden;
}
