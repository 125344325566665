@import 'assets/styles/mixins';

.container {
  @include padding('top');

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: var(--primary-color);
    font-weight: bold;
    text-align: center;
  }

  p {
    text-align: center;
  }
}

.mobile-container {
  @extend .container;
  padding: 1rem;
}
