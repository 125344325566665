@import "assets/styles/mixins";

// Create Grid Classes
@for $i from 1 through 12 {
  @for $x from 1 through 12 {
    .grid-#{$i}-#{$x} {
      display: grid;
      width: 100%;
      grid-template-columns: repeat(#{$i}, 1fr);
      grid-template-rows: repeat(#{$x}, auto);
      grid-column-gap: 0.5rem;
      grid-row-gap: 0.5rem;

      @include tablet {
        grid-column-gap: 0.75rem;
        grid-row-gap: 0.75rem;
      }

      @include desktop {
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
      }
    }
  }
}

.no-gap {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
