.dropdown-container {
  width: 400px;
}

.dropdown-content {
  padding: 0 0.5rem;
}

.dropdown-section {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
