@import "./variables";

.hoverable {
  &:hover {
    cursor: pointer;
  }
}

.highlighted {
  border-color: var(--hover-border-color);
  background-color: var(--hover-color);
  color: var(--primary-color);
}

.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}

.bordered {
  border: 1px solid var(--border-color);
}

.bordered-left {
  border-left: 1px solid var(--border-color);
}

.bordered-right {
  border-right: 1px solid var(--border-color);
}

.bordered-top {
  border-top: 1px solid var(--border-color);
}

.bordered-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-radius-none {
  border-radius: 0;
}

.border-radius-sm {
  border-radius: var(--border-radius-sm);
}

.border-radius-xs {
  border-radius: var(--border-radius-xs);
}

.line-height-3 {
  line-height: 1rem;
}

.circle {
  border-radius: 100%;
}

.borderless {
  border: none !important;
}

.background-card {
  background: var(--card-background-color);
}

.background-primary {
  background-color: var(--hover-color);
  color: var(--primary-color);
}

.box-shadow {
  height: 100%;
  box-shadow: #{$card-box-shadow};
  border-radius: 10px;
}
.box-border { 
  border: 1px solid var(--border-color);
}
