.dropdown-item, .subnav-item {
  cursor: pointer;
  white-space: nowrap;
  padding: 4px;
}

.dropdown-item {
  &:last-child {
    margin-bottom: 0;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .sub-menu {
    @extend .sub-menu-showing;
    display: none;
  }

  .dropdown-item .sub-menu-showing  {
    top: 0;
    margin-left: 1px;
    margin-right: auto;
  }

  .sub-menu-showing {
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
	}
  .subnav-label, .subnav-switch-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    &:hover {
      color: var(--primary-color) !important;
      background-color: var(--hover-color);
    }

  }
  .subnav-label {
    padding: 4px;
  }
  .subnav-switch-label {
    .switch {
      svg {
        height: 28px;
        width: 28px;
        margin-left: 1.25rem;
      }
    }
  }
}

.dropdown-item {
  p {
    line-height: 12px;
    margin-bottom: 0 !important;
    min-width: 140px;
    padding: 0.35rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
  }
  p:not(.menu-item-title):hover {
    color: var(--primary-color) !important;
    background-color: var(--hover-color);
    margin-bottom: 0 !important;
    padding: 0.35rem;
  }
  .menu-item-title {
    background-color: #f7f7f7;
    font-weight: 700;
  }
}

