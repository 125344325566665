.footer-button {
  padding: 0.75rem !important;
  width: 90vw;
  min-height: 44px;
}

.tablet-footer-button {
  @extend .footer-button;
  margin-right: 60px;
}
