@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

$padding: 0.75rem;
$transition: 0.35s ease-in-out;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 78px;
  background-color: var(--blue-900);
  transition: $transition;
  font-family: 'Source Sans Pro', sans-serif;
  &.open {
    box-shadow: 3px 0px 10px 0px rgba(0, 0, 0, 0.2);
    width: 278px;
  }
}

.menu-icon {
  position: absolute;
  top: 20px;
  &:hover {
    cursor: pointer;
  }
}

.menu-icon-expanded {
  position: absolute;
  top: 1.8rem;
  &:hover {
    cursor: pointer;
  }
}

.modules-container {
  margin-top: 5rem;
  height: calc(100% - 12rem);
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--blue-900);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-500) !important;
    border-radius: 5px;
    z-index: 3;
  }
}
.modules-container-expanded {
  @extend .modules-container;
  margin-top: 6rem;
  margin-bottom: 1rem;
  height: calc(100% - 15rem);
  width: 100%;
}

.modules-container-title-expanded {
  @extend .modules-container-expanded;
  height: calc(100% - 20rem);
}

.modules-container-expanded-smaller {
  @extend .modules-container-expanded;
  height: calc(100% - 15rem);
}
.modules-container-title-expanded-smaller {
  @extend .modules-container-expanded-smaller;
  height: calc(100% - 22rem);
}

.profile-icon {
  position: absolute;
  bottom: 2rem;
  &:hover {
    cursor: pointer;
    color: #fff;
  }
}
.profile-icon-expanded {
  bottom: 1rem;
  transition: $transition;
  width: 100%;
}
