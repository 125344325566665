.mobile-item {
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  color: var(--gray-300);
}

.item {
  padding: 0.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 14px;
  line-height: 20px;
  color: var(--blue-300);
}

.selected {
  background-color: var(--blue-300);
  color: var(--dark);
}
.mobile-selected {
  color: #fff;
}
