.color-check {
  color: #0d6efd;
}

.input-item {
  display: flex;
  align-items: center;
  min-height: 44px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  padding: 0.25rem;
  background: #f4f2f1;
  svg {
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    min-height: 18px;
    min-width: 18px;
  }
  margin-bottom: 0.5rem;
}

.title-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  text-wrap: wrap;
  max-width: 25rem;
}

.apply-button {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background: #fff;
  padding-top: 0.5rem;
}

.dropdown-title {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-data {
  font-style: italic;
  opacity: 0.5;
}
