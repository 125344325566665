.login-error {
  border: 1px solid var(--color-error);
  color: var(--color-error);
  background: var(--color-error-background);
  border-radius: var(--border-radius-xs);
  padding: 0.5em;
  margin-top: 1.5em;
}

.text-align-right {
  text-align: right;
  width: 100%;
  display: block;
}

.kwello-login-icon {
  height: 100px;
  width: 250px;
}

.mobile {
  padding: 5%;
  .kwello-login-icon {
    margin-top: 10%;
  }
  h2 {
    margin-left: 45px;
  }
  h4 {
    margin-left: 25px;
  }
  .mobile-greeting {
    padding-top: 5%;
    padding-bottom: 5%;
  }
}
