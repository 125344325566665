@import 'assets/styles/mixins';

.container {
  height: 100%;
  overflow-y: auto;
  padding-top: 0.5em;
  &::-webkit-scrollbar {
    background-color: #fff;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
  @include scrollbar();
  padding-right: 0;
}
