$spacing: 0.75em;
@import "assets/styles/mixins";

.container {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-xs);
  padding: $spacing;
  height: 100%;

  &.selected {
    border-color: var(--primary-color);
  }

  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    padding: 0.15em 0;
    font-weight: bold;
    font-size: 1.45em;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      cursor: pointer;
    }
  }
}

.business-owner {
  border-bottom: 1px solid var(--border-color);
  font-weight: 600;
}

.alert-frequency-button {
  &:hover {
    cursor: pointer;
  }
}

.detail-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: .75rem 0;

  &:first-child {
    padding-top: .25rem;
  }

  span {
    display: block;

    &.detail-label {
      padding-right: .5rem;
      width: 40%;

      @include desktop {
        width: 30%;
      }
    }

    &.detail-value {
      font-weight: 600;
      width: 60%;

      @include desktop {
        width: 70%;
      }
    }
  }
}

