@import 'assets/styles/mixins';

$item-border: 1px solid var(--border-color);

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-warning);
}

.container {
  flex: 1;
  align-items: flex-start;
  padding: 0 0.5rem;
  border-left: $item-border;

  @include largeDesktop {
    padding: 0 1rem;
  }

  &:first-child {
    padding-left: 0;
    border-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.grouped-item {
    position: relative;
    border-left: $item-border;
    padding-left: 0.5rem;

    @include desktop {
      margin-right: 1.5rem;
    }

    & ~ .grouped-item {
      border-left: 0;
    }

    .count-container {
      @include desktop {
        &::after {
          content: 'VS';
          background-color: var(--filter-background-color);
          padding: 2px;
          // margin: 0 0.5em;
          position: absolute;
          left: 100%;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &:last-child {
      margin-right: 0;
      .count-container {
        &::after {
          display: none;
        }
      }
    }
  }
}

.summary-item {
  flex-wrap: wrap;

  .label {
    display: block;
    position: relative;
    text-wrap: nowrap;
  }
}

.count-container {
  display: flex;
  align-items: center;

  .count {
    display: inline-block;
    font-size: 1.25em;
    font-weight: 500;

    @include desktop {
      font-size: 1.5em;
    }

    @include largeDesktop {
      font-size: 1.75em;
    }
  }

  .tooltip {
    overflow-x: hidden;
  }

  :global(.badge) {
    display: none;
    font-size: 1em;
    padding: 2px 4px 1px 7px;
    margin-left: 0.5em;

    @include desktop {
      display: inline-block;
    }

    &::before {
      border-style: solid;
      border-width: 1.5px 1.5px 0 0;
      content: '';
      display: inline-block;
      height: 0.25rem;
      left: -0.2rem;
      position: relative;
      top: 0.375rem;
      transform: rotate(-45deg);
      vertical-align: top;
      width: 0.25rem;
    }

    &:global(.bg-danger)::before {
      top: 0.3rem;
      transform: rotate(135deg);
    }
  }
}

.label-container {
  position: relative;
  margin-right: 0.5rem;

  &.bar {
    span {
      padding-left: 0.25rem;
    }
  }

  .bar {
    background-color: var(--primary-color);
    height: 100%;
    position: absolute;
  }
}

.icon {
  margin-left: 0.5rem;
}

.clickable {
  cursor: pointer;
}
