.table {
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  tr {
    border-radius: 5px;
  }
  tbody tr {
    cursor: pointer;
    &:hover {
      background-color: var(--hover-color);
      border-left: 2px solid var(--primary-color);
      & > td {
        border-top: 1px solid var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        box-shadow: none;
        &:first-child {
          border-left: 1px solid var(--primary-color);
        }
        &:last-child {
          border-right: 1px solid var(--primary-color);
        }
      }
    }
  }
  td {
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  thead tr {
    border-top: none;
    th {
      border-left: none;
      border-right: none;
      border-top: none;
    }
  }
  .table-selected-row {
    background-color: var(--hover-color);
    border-left: 2px solid var(--primary-color);
    & > td {
      border-top: 1px solid var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
      box-shadow: none;
      &:first-child {
        border-left: 1px solid var(--primary-color);
      }
      &:last-child {
        border-right: 1px solid var(--primary-color);
      }
    }
  }
  .selection-cell {
    input[type="checkbox"] {
      display: none;
    }
  }
}
