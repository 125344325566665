.container {
  position: relative;
}

.radio-group {
  padding: 0.75em;
  display: flex;
  justify-content: center;
}

.button {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
