@import "assets/styles/mixins";

.header {
  display: flex;
  align-items: center;
  @include margin(bottom);

  h5 {
    margin: 0;
    flex: 1;
    font-weight: bold;
  }
}

.search {
  @include margin(bottom);
}