.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    display: block;
  }

  span {
    display: block;
    margin-left: 40px;
    padding-top: 7px;
    font-size: 1.2em;
  }
}