.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  transition: all 0.5s ease-in-out;
  margin-right: -12px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active.expanded {
    flex: 1;
    overflow: hidden;
    background-color: var(--dark);
    color: var(--text-contrast-color);
  }
}

.mobile-container {
  @extend .container;
  margin-right: 0;
  margin-left: 0.75rem;
  border-radius: 0.5rem;
  &.mobile-active.mobile-expanded {
    color: #fff;
  }
  .scroll {
    padding-left: 1.5rem;
  }
}

.button {
  text-align: left;
  background-color: var(--dark);
  border-radius: 4px;
  border: none;

  &.active {
    background-color: var(--card-background-color);
    color: var(--primary-color);
  }
}

.mobile-button {
  @extend .button;
  color: var(--gray-300);
  svg {
    color: var(--gray-300);
  }
}

.mobile-active {
  color: #fff !important;
  svg {
    color: var(--primary-color) !important;
  }
}

.mobile-content {
  max-height: 15rem;
  overflow-y: auto;
  padding: 1rem;
  background-color: var(--dark);
}

.scroll {
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--background-panel);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-color) !important;
    border-radius: 5px;
    z-index: 3;
  }
}

.mobile-name-dropdown {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: var(--dark);
  border-radius: 4px;
  .arrow-icon {
    color: #fff;
  }
}

.rotate {
  transform: rotate(180deg);
}
