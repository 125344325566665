@import "assets/styles/mixins";

.search-bar-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include desktop {
    flex-wrap: nowrap;
  }
}

.filter-container {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  background-color: var(--darker-background-color);

  @include desktop {
    margin-top: 0;
  }
}

.filter-buttons-container {
  flex: 1;
  padding: 0.75em;
  padding-bottom: 0;

  > * {
    padding-bottom: 0.75em;
  }
}

.filter-save-icon-container {
  display: flex;
  align-items: center;
  border-left: 1px solid var(--border-color);
  padding: 0 1.2em;
}

.button-skeleton {
  position: relative;
  top: 3px;
}
