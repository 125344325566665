.container {
  position: relative;

  .content {
    position: absolute;
    display: none;
    z-index: 30;
    background: #dedede;
    color: var(--text-main-color);
    border-radius: .25em;
    padding: .5em;
    width: max-content;
    max-width: 300px;

    &.top {
      bottom: calc(100% + 14px);
      left: 50%;
      transform: translateX(-50%);
      // put a triangle at the bottom of this element
      &::before {
        content: '';
        position: absolute;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
        border: .5em solid transparent;
        border-top-color: #dedede;
      }
    }

    &.bottom {
      top: calc(100% + 14px);
      left: 50%;
      transform: translateX(-50%);
      // put a triangle at the top of this element
      &::before {
        content: '';
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translateX(-50%);
        border: .5em solid transparent;
        border-bottom-color: #dedede;
      }
    }

    &.left {
      right: calc(100% + 14px);
      top: 50%;
      transform: translateY(-50%);
      // put a triangle at the right of this element
      &::before {
        content: '';
        position: absolute;
        right: -14px;
        top: 50%;
        transform: translateY(-50%);
        border: .5em solid transparent;
        border-left-color: #dedede;
      }
    }

    &.right {
      left: calc(100% + 14px);
      top: 50%;
      transform: translateY(-50%);
      // put a triangle at the left of this element
      &::before {
        content: '';
        position: absolute;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
        border: .5em solid transparent;
        border-right-color: #dedede;
      }
    }
  }

  &:not(.disabled):hover .content {
    display: block;
  }
}