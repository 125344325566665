.collection-container {
  border: 1px solid var(--border-color);
  min-height: 40vh;
  padding-left: 1rem;
}

.modal-content {
  h6 {
    padding-top: 0.5rem;
  }
}

.create-section {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.collection-item {
  margin-bottom: 0.25rem;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
  margin-top: 0.5rem;
}
