@import 'assets/styles/mixins';

.container {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: var(--border-radius-xs);
  border-bottom: 1px solid var(--border-color);

  @include padding('top');
  @include padding('bottom');

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.selected {
    border-color: var(--primary-color);
  }

  & > * {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .details {
    flex: 1;

    span {
      display: block;
    }
  }
}

.search-name {
  font-weight: bold;
  font-size: 1.2em;
}

.badge {
  font-size: 1rem;
  padding: 0.25rem;
  border-radius: var(--border-radius-sm);
}

.filterCount {
  margin-left: 0.25rem;
}
