.container {
  width: 1072px;
  max-width: 90%;
  margin: 0 auto;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 0;
}

.content {
  background-color: #fff;
  padding: 1.5em;
  margin-bottom: 5em;

  h6 {
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: var(--primary-color);
  }
}
