.container {
  position: relative;
  display: inline-block;

  .content {
    position: fixed;
    visibility: hidden;
    z-index: 30;
    background: #dedede;
    color: var(--text-main-color);
    border-radius: 0.25em;
    padding: 0.5em;
    width: max-content;
    max-width: 300px;
    text-wrap: wrap !important;
    line-height: 18px;
    white-space: normal; //Safari browser fix

    &.top {
      // put a triangle at the bottom of this element
      &::before {
        content: '';
        position: absolute;
        bottom: -12px;
        left: 50%;
        border: 0.5em solid transparent;
        border-top-color: #dedede;
      }
    }

    &.bottom {
      // put a triangle at the top of this element
      &::before {
        content: '';
        position: absolute;
        top: -12px;
        left: 50%;
        border: 0.5em solid transparent;
        border-bottom-color: #dedede;
      }
    }

    &.left {
      // put a triangle at the right of this element
      &::before {
        content: '';
        position: absolute;
        right: -12px;
        top: 20%;
        border: 0.5em solid transparent;
        border-left-color: #dedede;
      }
    }

    &.right {
      // put a triangle at the left of this element
      &::before {
        content: '';
        position: absolute;
        left: -12px;
        top: 20%;
        border: 0.5em solid transparent;
        border-right-color: #dedede;
      }
    }
  }

  &:not(.disabled):hover .content {
    visibility: visible;
  }
}
