.gray-text {
  color: var(--text-grey-color);
}

.text-highlighted {
  color: var(--primary-color) !important;
}

.text-muted {
  color: var(--text-grey-color);
}

.text-xs {
  font-size: 0.75rem;
}

.text-sm {
  font-size: 0.875rem;
}

.line-height-sm {
  line-height: 0.875rem;
}

.text-regular {
  font-size: 1rem;
}

.text-md {
  font-size: 1.125rem;
}

.text-l {
  font-size: 1.25rem;
}

.text-xl {
  font-size: 1.5rem;
}

a {
  text-decoration: none;
  color: var(--text-main-color);
  &:hover {
    color: var(--text-main-color);
  }
}
