@import "assets/styles/mixins";

.main-content-wrapper {
  background-color: #fff;
  border-radius: var(--border-radius-sm);
  padding: 1.5rem;
  box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
}

.content {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 82vh;
  @include padding();
}

.search {
  @include margin("bottom");
}

.scroll {
  position: absolute;
  left: 0;
  right: 0;
}

.scroll-wrapper {
  flex: 1;
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  @include padding();
  background-color: #fff;
}