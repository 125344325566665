@import 'assets/styles/mixins';

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  @include margin('top');

  &:hover {
    cursor: pointer;
  }

  .message-container {
    margin-left: 0.5rem;
  }

  span {
    display: block;

    &.title {
      font-weight: bold;
    }
  }
}

.no-lists {
  text-align: center;
  font-size: 1.25rem;
}
