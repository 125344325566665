.container {
  display: inline-block;
  background-color: var(--filter-background-color);
  border-radius: var(--border-radius-xs);
  padding: 0 .25rem;
  font-size: .80em;
}

.error {
  color: var(--color-error);
  background-color: #d9303830;
}

.icon {
  margin-right: .25rem;
}
