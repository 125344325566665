.expanded {
  font-size: 16px;
  line-height: 18.4px;
}
.container {
  margin-bottom: 1rem;
  transition: all 0.5s ease-in-out;
  border-radius: 2px;
  margin: 0.5rem;
}
.active {
  background-color: var(--dark);
  color: #fff;
  border-radius: 4px;
  svg {
    color: #f36633;
  }
}

.not-active {
  color: var(--gray-500);
  svg {
    color: var(--gray-500);
  }
}

.icon-name {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  &:hover {
    cursor: pointer;
  }
}

.content {
  max-height: 15rem;
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: var(--blue-900);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray-500) !important;
    border-radius: 5px;
    z-index: 3;
  }
}

.menu-tag {
  margin-left: auto;
  background-color: transparent;
  border-radius: 16px;
  border: 2px solid var(--gray-300);
  font-size: 0.75rem;
  padding: 0 6px;
}
