$spacing: 0.75em;

.container {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-xs);
  padding: $spacing;
  height: 100%;

  &.selected {
    border-color: var(--primary-color);
  }

  &.pointer {
    &:hover {
      cursor: pointer;
    }
  }
}

.profile-picture-container {
  margin-right: $spacing;
  button {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }
}

.heading-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}

.delete-contribution {
  margin-left: auto;
}

.name {
  padding: 0.15em 0;

  button {
    padding: 0;
    font-weight: bold;
    font-size: 1.45em;
    white-space: normal;
    line-height: 1.2em;
    text-align: left;

    &:hover {
      background: transparent;
    }
  }
}

.body-container {
  margin-top: $spacing;
}

.body-block {
  margin-bottom: $spacing;

  &:last-child {
    margin-bottom: 0;
  }
}

.contribution {
  &:hover {
    cursor: pointer;
  }
}

.icons {
  background-color: var(--filter-background-color);
  padding: .5rem;
  border-radius: var(--border-radius-sm);
  width: fit-content;
  display: flex;
  margin-right: 0.5rem;
}

.iconSection {
  display: flex;
}

.svgMargin {
  svg {
    margin-right: 0.5rem;
  }
}

.date { 
  margin-left: .25rem; 
}
