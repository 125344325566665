.btn-dropdown {
  border: var(--border-color) 1px solid;
  background-color: var(--card-background-color);
  &:hover,
  &:focus {
    border-color: var(--border-color);
    box-shadow: none;
  }
  &.no-arrow {
    &:after {
      display: none;
    }
  }
}

.btn-transparent-dropdown {
  border: none;
  background-color: transparent;
  &:hover,
  &:focus {
    box-shadow: none;
  }
  &.no-arrow {
    &:after {
      display: none;
    }
  }
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
  &:hover,
  &:focus {
    background-color: var(--primary-hover-color);
    border-color: var(--primary-hover-color);
    box-shadow: none;
  }
}

.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
  &:hover,
  &:focus {
    background-color: var(--primary-hover-color); //TODO
    border-color: var(--primary-hover-color);
    box-shadow: none;
  }
}

.btn-light {
  background-color: var(--filter-background-color);
  border-color: var(--border-color);
  &:hover,
  &:focus {
    border-color: var(--border-color);
    box-shadow: none;
  }
}

.background-primary-button {
  background-color: var(--hover-color) !important;
  color: var(--primary-color);
  svg {
    color: var(--primary-color);
  }
}

.btn-red {
  color: var(--color-warning) !important;
}

.btn-white {
  background: #fff !important;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}
