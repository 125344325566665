.toast {
  border: none !important;
}

.toast-header {
  border: 1px solid var(--color-success);
  border-radius: var(--border-radius-sm);
  color: var(--color-success);
  background: rgba(255, 255, 255, 0.85);
  font-size: 1rem;
  font-weight: bold;
  button {
    color: var(--color-success);
    background-color: #c4f4e1;
    border-radius: 100%;
  }
}
