.feed-body-container {
  &:before {
    mask: url(./../../../../assets/images/feedBackground.svg) repeat;
    background-color: var(--primary-color);
    opacity: 0.25;
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 82vh;
    pointer-events: none;
  }
}

.feed-body-content {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  position: absolute;
  height: 82vh;
  right: 0;
  left: 0;
  overflow-y: auto;
}
.desktop {
  padding: 0 0.25rem 0 0.25rem;
}
