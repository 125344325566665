.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 1rem;
}

.container {
  overflow-x: hidden;
  width: 600px;
}