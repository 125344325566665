.text {
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 0.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.url {
  font-size: 1rem;
  font-weight: normal;
  color: var(--text-color);
  background-color: var(--darker-background-color);
  border-radius: var(--border-radius-sm);
  padding: 0.75em;
  margin-bottom: 0.5em;
}
