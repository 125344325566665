@import "./variables";

@for $s from 1 through length($screens) {
  $screen: nth($screens, $s);
  $size: nth($screen-sizes, $s);

  @if $screen != "" {
    .hide-#{$screen}-up {
      @media only screen and (min-width: #{$size}) {
        display: none !important;
      }
    }

    @if $s != length($screen-sizes) {
      .hide-#{$screen}-down {
        @media only screen and (max-width: #{nth($screen-sizes, $s + 1)}) {
          display: none !important;
        }
      }
    }
  }
}
