.container {
  position: relative;
  display: inline-block;
}

.select-container {
  padding: 0.35rem;
  outline: 1px solid var(--border-color);
}

.dropdown-content {
  border-radius: 0.25rem;
  outline: 1px solid var(--border-color);
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #fff;
  padding: 1em 1em;
  font-size: 0.875rem;

  &.open {
    display: block;
  }
}

.click-element {
  svg {
    margin-left: .4rem;
  }
}
.click-element-right-margin {
  svg {
    margin-left: .4rem;
  }
  margin-right: .75rem;
}