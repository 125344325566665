@import 'assets/styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
  background-color: #fff;
  @include negative-margin('left');
  @include negative-margin('right');
  @include padding();
}

.nav-left {
  display: flex;
  align-items: center;
}

.nav-right {
  display: flex;
  align-items: center;
}

.logo {
  height: 28px;
  margin-right: 0.75rem;
}

.page-label {
  border-left: 1px solid var(--border-color);
  padding-left: 0.75rem;
  a {
    font-size: 1.25em;
    line-height: 1em;
    color: var(--text-main-color) !important;
  }
}

.nav {
  @include margin('bottom');
}

.summary-bar {
  @include margin('bottom');
}
.sub-nav {
  overflow: hidden;
  padding: 0;
  padding-left: 170px;
  height: 0;
  justify-content: flex-start;
  transition: height 0.3s ease-in-out;

  &.open {
    height: 50px;
    border-top: 1px solid var(--border-color);
  }

  &.sosv {
    padding-left: 270px;
  }
}

.logo-container {
  display: flex;
}
