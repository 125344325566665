.close {
  position: absolute;
  top: 0;
  right: -47px;
}

.action-buttons {
  display: flex;
  column-gap: 4px;
}
