.checkbox-row-container {
  border-top: 1px solid var(--border-color);
  padding-top: 2px;
}

.boolean-radio-group {
  input {
    display: none;
  }
  .form-check {
    padding-left: 0;
    label {
      padding: 0.5rem 0.5rem 0.5rem 2.375rem;
      position: relative;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-xs);
      line-height: 1rem;
      &:before {
        position: absolute;
        left: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        border: 1.5px solid #dedede;
        border-radius: 100%;
        background-color: #f7f7f7;
        content: "";
      }
    }

    &.active {
      label {
        border: 1px solid var(--color-success);
        background-color: var(--success-background-color);
        &:before {
          content: "";
          background-image: url(./../icons/checkCircleGreen.svg);
          background-size: contain;
          border: none;
          border-radius: initial;
          background-color: transparent;
        }
      }
    }
  }
}

.react-toggle {
  .react-toggle-track {
    height: 1rem;
    width: 1.75rem;
    background-color: #f7f7f7 !important;
    border: 1px solid #dedede;
  }
  .react-toggle-thumb {
    height: 0.875rem;
    width: 0.875rem;
    top: 0.0625rem;
    left: 0.0625rem;
    border: none;
    background-color: #dedede;
    box-shadow: none !important;
  }
  &.react-toggle--checked {
    .react-toggle-thumb {
      border: none;
      background-color: var(--color-success) !important;
      left: 0.75rem;
    }
    .react-toggle-track {
      background-color: #f7f7f7 !important;
    }
  }
}
