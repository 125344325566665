$vertical-space: 6px;
$horizontal-space: 10px;

@function button-vertical-space($button-size) {
  @if $button-size == 'small' {
    @return 4px;
  }

  @if $button-size == 'large' {
    @return 8px;
  }

  @return 6px;
}

@function button-horizontal-space($button-size) {
  @if $button-size == 'small' {
    @return 8px;
  }

  @if $button-size == 'large' {
    @return 12px;
  }

  @return 10px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button-container {
  display: inline-block;

  &.block {
    display: block;
    width: 100%;
  }
}

.button-dropdown {
  display: inline-block;
}

.button {
  position: relative;
  background-color: transparent;
  border: 1px solid var(--border-color);
  font-weight: 500;
  font-size: 0.875rem;
  font-family: primary-font;
  transition: all 0.25s ease;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  white-space: nowrap;

  &:not(:only-child) {
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.loading {
    .loading-spinner {
      opacity: 1;
    }
  }

  &.block {
    width: 100%;

    &:not(:only-child) {
      width: calc(100% - 35px);
    }
  }

  &.top {
    top: auto;
    bottom: 20px;
    position: fixed;
    z-index: 5;
    border-radius: 100px;
    width: 136px;
  }
}

.wrap {
  white-space: normal;
}

.no-click {
  pointer-events: none;
}

.size-small {
  padding: button-vertical-space(('small')) button-horizontal-space('small');

  svg {
    width: 15px;
    height: 15px;
  }

  .button-icon {
    margin-right: button-horizontal-space('small');
  }

  .dropdown-icon {
    margin-left: button-horizontal-space('small');
  }

  .loading-spinner {
    padding: button-vertical-space(('small')) button-horizontal-space('small');
  }
}

.size-medium {
  padding: button-vertical-space(('medium')) button-horizontal-space('medium');

  svg {
    width: 18px;
    height: 18px;
  }

  .button-icon {
    margin-right: button-horizontal-space('medium');
  }

  .dropdown-icon {
    margin-left: button-horizontal-space('medium');
  }

  .loading-spinner {
    padding: button-vertical-space(('medium')) button-horizontal-space('medium');
  }
}

.size-large {
  padding: button-vertical-space(('large')) button-horizontal-space('large');

  svg {
    width: 36px;
    height: 36px;
  }

  .button-icon {
    margin-right: button-horizontal-space('large');
  }

  .dropdown-icon {
    margin-left: button-horizontal-space('large');
  }

  .loading-spinner {
    padding: button-vertical-space(('large')) button-horizontal-space('large');
  }
}

.radius-small {
  border-radius: 2px;
}

.radius-medium {
  border-radius: 4px;
}

.radius-large {
  border-radius: 6px;
}
.radius-xlarge {
  border-radius: 100px;
}

.radius-xlarge {
  border-radius: 15px;
}

.radius-round {
  border-radius: 50%;
}

.button-icon {
  margin-top: -2px;

  &.icon-only {
    margin-right: 0;
  }
}

.dropdown-icon {
  margin-top: -3px;
}

.secondary-icon {
  margin-top: -2px;
}

.primary {
  background-color: var(--primary-button-color, #f76900);
  border-color: var(--primary-button-color, #f76900);
  color: #fff;

  &.loading {
    color: var(--primary-color, #f76900);
  }

  &:hover {
    background-color: var(--primary-hover-color);
    border-color: var(--primary-hover-color);
  }

  &:disabled {
    @extend .disabled;
  }
}

.primary-outline {
  @extend .primary;
  background-color: #fff;
  color: #f76900;
  &:hover {
    background-color: var(--primary-hover-color);
    border-color: var(--primary-hover-color);
    color: #fff;
  }
}

.loading-spinner {
  position: absolute;
  inset: 0;
  opacity: 0;
  max-width: 100%;
}

.secondary {
  background-color: #fafafa;
  border-color: #dedede;
  color: #333;

  &.loading {
    color: #fafafa;
  }

  &:hover {
    background-color: darken(#fafafa, 5%);
  }

  &:disabled {
    @extend .disabled;
  }
}

.secondary-white {
  @extend .secondary;
  background-color: #fff;
  border: 1px solid #473a2e;
  min-height: 44px;
}

.contrast {
  background-color: var(--blue-500);
  border-color: var(--blue-500);
  color: #fff;
  min-height: 44px;

  &.loading {
    color: var(--blue-500);
  }

  &:hover,
  &:active {
    background-color: rgba(51, 51, 51, 0.6);
    border-color: rgba(51, 51, 51, 0.6);
  }

  &:disabled {
    background: var(--gray-100);
    color: var(--gray-500);
    @extend .disabled;
  }
}

.transparent {
  @extend .secondary;

  background-color: transparent;
  border-color: transparent;

  &.loading {
    color: #fafafa;
  }
}

.transparent-primary {
  @extend .transparent;

  color: var(--primary-color);
}

.transparent-warning {
  @extend .transparent;

  color: var(--color-warning);
}

.warning {
  @extend .secondary;

  color: var(--color-warning);

  &.loading {
    color: #fafafa;
  }
}

.filter {
  background-color: #fff;
  border-color: #dedede;
  color: #333;
  margin-right: 0.5rem;

  &:not(:only-child) {
    &:first-child {
      margin-right: 0;
    }
  }

  &.loading {
    color: #fafafa;
  }

  &:hover {
    background-color: #fff;
  }

  svg {
    stroke: #333;
  }

  &:disabled {
    @extend .disabled;
  }
}

.filter-primary {
  @extend .filter;

  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: var(--hover-color);

  &:hover {
    background-color: var(--hover-color);
  }
}

.insights-checked {
  @extend .filter;

  border-color: var(--gray-100);
  color: #fff;
  background-color: var(--blue-300);

  &:hover {
    background-color: var(--blue-300);
  }
}

.selected-insights {
  border-color: var(--navigation-color);
}

.filter-with-hover {
  @extend .filter;
  &:hover {
    background-color: #dedede;
  }
  &:active {
    border-color: #333;
  }
}

.selected {
  border-color: var(--primary-color);
}

.link {
  padding: 0;
  background-color: transparent;
  border: 0;
  color: var(--primary-color);

  &:hover {
    color: var(--primary-hover-color);
    text-decoration: underline;
  }

  &:disabled {
    @extend .disabled;
    text-decoration: none;
  }
}
.rotate {
  transform: rotate(180deg);
}

.center-button {
  display: flex;
  justify-content: center;
}
