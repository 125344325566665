.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.block {
  width: 100%;
}

.label {
  margin-right: 1em;
}

.button {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.icon {
  margin-top: -2px;
}
