.container {
  padding: 1em;
  padding-top: 0;

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .group-header {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    font-size: 1.25em;
  }

  .accordion {
    margin-bottom: 76px;
  }

  .accordion-item {
    border: none !important;
    padding: 7px 0;
  }

  .accordion-body {
    margin-top: 15px;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    padding: 0.5rem;
  }

  .footer {
    width: 475px;
    border: 1px solid var(--border-color);
    background: #fff;
    left: 0;
    padding: 20px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .active {
    display: flex;
    height: 10px;
    width: 10px;
    background: var(--primary-color);
    margin: 0 7px;
    border-radius: 100%;
  }
}

.mobile-container {
  @extend .container;
  margin-top: 5rem !important;
}
