.font-weight-bold {
  font-weight: bold;
}

.font-weight-500 {
  font-weight: 500;
}
.font-weight-700 {
  font-weight: 700;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-n2 {
  margin-left: -0.5rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-2-5 {
  padding: 0.75rem;
}
.px-2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.py-2-5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pl-2-5 {
  padding-left: 0.75rem;
}

.pr-2-5 {
  padding-right: 0.75rem;
}

.pb-2-5 {
  padding-bottom: 0.75rem;
}

.pt-2-5 {
  padding-top: 0.75rem;
}

.m-2-5 {
  margin: 0.75rem;
}

.ml-2-5 {
  margin-left: 0.75rem;
}

.mr-2-5 {
  margin-right: 0.75rem;
}

.mb-2-5 {
  margin-bottom: 0.75rem;
}

.mt-2-5 {
  margin-top: 0.75rem;
}

.flex-basis {
  flex-basis: 1px;
}

.flex-grow-1 {
  flex-grow: 1;
}
.flex-grow-2 {
  flex-grow: 2;
}
.flex-grow-3 {
  flex-grow: 3;
}
.flex-grow-4 {
  flex-grow: 4;
}

.text-justify {
  text-align: justify;
}

.bg-panel {
  background: var(--background-panel);
}
