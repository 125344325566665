.kwello-button {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 100px;
  cursor: pointer;
  border: 2px;
  display: flex;
  align-items: center;
}

.kwello-button-icon {
  border-radius: 50%;
  border: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kwello-button-icon--large {
  height: 40px;
  width: 40px;
}

.kwello-button-icon--medium {
  height: 32px;
  width: 32px;
}

.kwello-button-icon--small {
  height: 24px;
  width: 24px;
}

.kwello-button--small {
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  padding: 8px 12px 8px 12px;
}

.kwello-button--large {
  font-size: 14px;
  padding: 8px 16px 8px 16px;
  height: 40px;
  line-height: 16px;
}

.kwello-button-icon--right {
  display: flex;
}
.kwello-button-icon--left {
  display: flex;
  flex-direction: row-reverse;
}
.kwello-label {
  padding: 0px 8px 0px 8px;
}

.kwello-button--loudOutline {
  background-color: #fff;
  border: 2px solid var(--orange-500);
  color: var(--orange-500);
  &:disabled {
    border: 2px solid var(--gray-300);
    color: var(--gray-300);
    pointer-events: none;
  }
  &:hover,
  &:active {
    color: #cd5700;
    border: 2px solid #cd5700;
  }
}

.kwello-button--loud {
  background-color: var(--orange-500);
  color: #fff;
  &:disabled {
    background-color: var(--gray-500);
    color: var(--gray-300);
    pointer-events: none;
  }
  &:hover,
  &:active {
    background-color: #cd5700;
  }
}

.kwello-button--contrastOutline {
  background-color: #fff;
  border: 2px solid var(--blue-500);
  color: var(--blue-500);
  &:disabled {
    color: var(--gray-300);
    border: 2px solid var(--gray-300);
    pointer-events: none;
  }
  &:hover,
  &:active {
    color: var(--gray-700);
    border: 2px solid var(--gray-700);
  }
}

.kwello-button--contrast {
  background-color: var(--blue-500);
  color: white;
  &:disabled {
    background-color: var(--gray-500);
    color: var(--gray-300);
    pointer-events: none;
  }
  &:hover,
  &:active {
    background-color: var(--blue-700);
  }
}

.kwello-button--softOutline {
  background-color: #fff;
  border: 2px solid var(--gray-900);
  color: var(--gray-900);
  &:disabled {
    color: var(--gray-300);
    border: 2px solid var(--gray-300);
    pointer-events: none;
  }
  &:hover,
  &:active {
    color: var(--gray-700);
    border: 2px solid var(--gray-700);
  }
}

.kwello-button--soft {
  background-color: var(--gray-300);
  color: var(--gray-900);
  &:disabled {
    color: #fff;
    pointer-events: none;
  }
  &:hover,
  &:active {
    background-color: var(--gray-500);
  }
}
