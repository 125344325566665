.input-group {
  display: flex;
  flex-wrap: wrap;

  button {
    margin-bottom: 0.5rem;
  }

  margin-bottom: -0.5rem;
}

.item-container {
  display: flex;
  align-items: baseline;
  input {
    margin-right: 0.4rem;
  }
}

.input-item {
  display: flex;
  align-items: center;
  min-height: 44px;
  background-color: #f3f8ff;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  padding: 0.25rem;
  svg {
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    min-height: 18px;
    min-width: 18px;
  }
  margin-bottom: 0.5rem;
}

.color-check {
  color: #0d6efd;
}

.input-item-desktop {
  display: flex;
  align-items: center;
  background-color: #f3f8ff;
  border: 1px solid #f3f8ff;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
}
.input-item-desktop-selected {
  @extend .input-item-desktop;
  background-color: #e5f9f1;
  border: 1px solid #31b073;
}

.desktop-label {
  max-width: 90%;
}
